import { render, staticRenderFns } from "./carouselcompany.vue?vue&type=template&id=e3a2adf8&scoped=true&"
import script from "./carouselcompany.vue?vue&type=script&lang=js&"
export * from "./carouselcompany.vue?vue&type=script&lang=js&"
import style0 from "./carouselcompany.vue?vue&type=style&index=0&id=e3a2adf8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3a2adf8",
  null
  
)

export default component.exports