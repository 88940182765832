
export const NEWS = (state, news) => {

    state.news = news;
}
export const NEWSSHOW = (state, news_show) => {

    state.news_show = news_show;
}
export const SEARCHNEWS = (state, news_search) => {

    state.news_search = news_search;
}
