import axios from "../../libs/axios";

export const getHome = ({ commit }) => {
  // console.log(commit)
  // store.state.loadingShow = true;
  axios
    .get("contents/home/hero_page")
    .then((response) => {
      commit("HOMEHERO", response.data.data);
    })
    .catch((errors) => {});
  axios
    .get("contents/home/offerigns")
    .then((response) => {
      commit("HOMEOFFERINGS", response.data.data);
    })
    .catch((errors) => {});
  axios
    .get("contents/home/people")
    .then((response) => {
      commit("HOMEPEOPLE", response.data.data);
    })
    .catch((errors) => {});
  axios
    .get("contents/home/partners_hero")
    .then((response) => {
      commit("HOMEPARTNERS", response.data.data);
    })
    .catch((errors) => {});
  axios
    .get("contents/home/partners_hero_images")
    .then((response) => {
      commit("HOMEPARTNERSIMAGES", response.data.data);
    })
    .catch((errors) => {});
};
