<template>
  <div class="home">
    <div class="first-section">
      <app-headertwo />
      <div class="container">
        <div class="row align-items-center pad-to-row">
          <div class="text-md-start col-lg-6">
            <div class="text-div">
              <h1>A True Devotion to</h1>
              <h4 v-if="HomeHero && HomeHero.title">{{ HomeHero.title }}</h4>
              <p v-if="HomeHero && HomeHero.description">
                {{ HomeHero.description }}
              </p>
            </div>
            <div class="left-btn d-flex justify-content-end">
              <router-link :to="{ name: 'about' }">Read More</router-link>
            </div>
          </div>
          <div class="col-lg-6 two-second">
            <img
              class="img-fluid"
              src="../assets/images/royalpharmarahome.png"
              alt="left_logo"
            />
          </div>
          <a class="newscroll" href="#second-section">
            <div class="scroll">
              <div class="scrolldown">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <p>Scroll</p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div
      class="second-section"
      id="second-section"
      v-if="HomeOfferings && HomeOfferings.length > 0"
    >
      <div class="container">
        <h2>Our Offerings</h2>
        <div class="sub-second-section">
          <div class="row first-row gx-2">
            <div
              class="col-lg-4 mx-auto"
              v-for="(item, index) in HomeOfferings"
              :key="index"
              :style="'z-index:' + (13 - index)"
            >
              <ouroffering :title="item.title" :description="item.description" />
            </div>
            <!-- <div class="col-lg-4" style="z-index: 12">
              <ouroffering />
            </div>
            <div class="col-lg-4" style="z-index: 11">
              <ouroffering />
            </div> -->
          </div>
          <!-- <div class="row justify-content-center second-row">
            <div class="col-lg-4" style="z-index: 10">
              <ouroffering />
            </div>
            <div class="col-lg-4">
              <ouroffering />
            </div>
          </div> -->
        </div>
      </div>
    </div> 
    <div class="thired-section" v-if="News && News.length > 0">
      <div class="container">
        <h2>latest News</h2>
        <div class="subsection">
          <div class="row gy-3">
            <card v-for="(card, index) in News" :key="index" :card="card" v-if="index < 3"/>
          </div>
        </div>
      </div>
    </div>
    <div class="fourth-section">
      <img
        src="../assets/images/right_our_people_home.svg"
        class="img-fluid svg_one"
        alt="svg_one"
      />
      <img
        src="../assets/images/left_our_people_home.svg"
        class="img-fluid svg_two"
        alt="svg_two"
      />
      <div class="container">
        <div class="text text-center">
          <h2>Our People</h2>
        </div>
        <div class="row">
          <newcardtwo
            v-for="product in HomePeople"
            :key="product.id"
            :product="product"
          />
        </div>
      </div>
    </div>
    <!-- <div class="fifth_section">
      <div class="container">
        <div class="head text-center">
          <h2>Our Global Partners</h2>
          <p v-if="HomePartners && HomePartners.description">
            {{ HomePartners.description }}
          </p>
        </div>
        <carouselcompany :companies="HomePartnerImages ? HomePartnerImages : []" />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import carouselcompany from "@/components/home/carouselcompany.vue";
import card from "@/components/news/card.vue";
import newcardtwo from "@/components/home/newcardtwo.vue";
import appHeadertwo from "@/components/global/appHeadertwo.vue";
import ouroffering from "@/components/home/ourOffering.vue";

// @ is an alias to /src

export default {
  name: "HomeView",
  components: {
    carouselcompany,
    card,
    newcardtwo,
    appHeadertwo,
    ouroffering,
  },
  data() {
    return {
      cards: [
        {
          id: 1,
          date: "20 jan 2022",
          title: "Get to know the sinovac vaccine",
          parg: "Lorem ipsum dolor sit amet, consectetur adipiscing",
          goto: "Read More",
          src: require("../assets/images/docotorhome.svg"),
        },
        {
          id: 2,
          date: "20 jan 2022",
          title: "Get to know the sinovac vaccine",
          parg: "Lorem ipsum dolor sit amet, consectetur adipiscing",
          goto: "Read More",
          src: require("../assets/images/docotorhome.svg"),
        },
        {
          id: 3,
          date: "20 jan 2022",
          title: "Get to know the sinovac vaccine",
          parg: "Lorem ipsum dolor sit amet, consectetur adipiscing",
          goto: "Read More",
          src: require("../assets/images/docotorhome.svg"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "HomeHero",
      "HomeOfferings",
      "HomePeople",
      "HomePartners",
      "HomePartnerImages",
      "News"
    ]),
  },
  mounted() {
    this.getHome();
    this.getNews();
  },
  methods: {
    ...mapActions(["getHome", "getNews"]),
  },
};
</script>

<style lang="scss" scoped>
@import "/src/assets/scss/pages/_home.scss";
</style>
