import axios from "../../libs/axios";

export const getAbout = ({ commit }) => {
  // console.log(commit)
  // store.state.loadingShow = true;
  axios
    .get("contents/about/hero_page")
    .then((response) => {
      commit("ABOUTHERO", response.data.data);
    })
    .catch((errors) => {});
  axios
    .get("contents/about/mission")
    .then((response) => {
      commit("ABOUTVISION", response.data.data);
    })
    .catch((errors) => {});
  axios
    .get("contents/about/director_message")
    .then((response) => {
      commit("ABOUTMANAGE", response.data.data);
    })
    .catch((errors) => {});
  axios
    .get("contents/about/principles")
    .then((response) => {
      commit("ABOUTPRINCIPLES", response.data.data);
    })
    .catch((errors) => {});
};
