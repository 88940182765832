
export const HOMEHERO = (state, hero) => {

    state.home_hero = hero;
}
export const HOMEOFFERINGS = (state, offerings) => {

    state.home_offerings = offerings;
}
export const HOMEPEOPLE = (state, people) => {

    state.home_people = people;
}
export const HOMEPARTNERS = (state, partners) => {

    state.home_partners = partners;
}
export const HOMEPARTNERSIMAGES = (state, partner_images) => {

    state.home_partner_images = partner_images;
}
export const HOMESHOW = (state, show) => {

    state.home_show = show;
}
