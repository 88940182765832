<template>
  <div v-if="lat && lng">
    <GmapMap
      :center="{ lat: lat, lng: lng }"
      :zoom="7"
      style="width: 100%; height: 399.48px; border-radius: 10px; border: 5px solid #ddd"
    >
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: "googlemapping",
  props: {
    lat: {
      type: Number,
      default: 0,
    },
    lng: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      coordinates: {
        lat: this.lat,
        lng: this.lng,
      },
    };
  },
  created() {
    this.$getLocation({}).then((coordinates) => {
      this.coordinates = coordinates;
    });
  },
};
</script>

<style lang="sass" scoped></style>
