<template>
  <div>
    <!-- Section first -->
    <div class="fourth_section">
      <div class="container">
        <div class="text">
          <h3>Our People</h3>
          <p v-if="MarketingPeople && MarketingPeople.description">
            {{ MarketingPeople.description }}
          </p>
        </div>
        <div
          class="row justify-content-center"
          v-if="PeopleImages && PeopleImages.length > 0"
        >
          <div
            class="col-sm-12 col-md-6 col-lg-3"
            v-for="(item, index) in PeopleImages"
            :key="index"
          >
            <div class="big-card">
              <div class="small-card">
                <img
                  :src="returnImage(item.description)"
                  class="img-fluid"
                  alt="photo of card"
                />
                <div class="card-info d-flex">
                  <span>{{ item.title }}</span>
                  <p>{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Section three -->
    <div class="fifth_section" v-if="PharmaYemen && PharmaYemen.length > 0">
      <img
        src="../assets/images/right_our_people_home.svg"
        class="img-fluid svg_one"
        alt="svg_one"
      />
      <img
        src="../assets/images/left_our_people_home.svg"
        class="img-fluid svg_two"
        alt="svg_two"
      />
      <div class="container">
        <div class="text text-center">
          <h2>Royal Pharmain Yemen</h2>
        </div>
        <div class="row">
          <newcards v-for="product in PharmaYemen" :key="product.id" :product="product" />
        </div>
      </div>
    </div>

    <!-- <div class="section_six" v-if="Highlights && Highlights.length > 0">
      <h2>2018 Business Heights</h2>
      <div class="first-div">
        <div class="row">
          <div class="col-lg-2 first"></div>
          <div class="col-lg-8 col-sm-12 second">
            <div class="business-height-content">
              <div class="row algin-items-center justify-content-center">
                <div class="col-sm-12 col-lg-7">
                  <p>
                    {{ Highlights[0].description }}
                  </p>
                </div>
                <div class="col-sm-12 col-lg-5 right-side">
                  <h2>{{ Highlights[0].title }}</h2>
                  <Doughnut :data-highlights="Highlights[0].highlights[0]" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2"></div>
        </div>
      </div>
      <div class="second-div">
        <div class="row g-0">
          <div class="col-lg-2"></div>
          <div class="col-lg-8 col-sm-12 second">
            <div class="business-height-content">
              <div class="row justify-content-center flex-row-reverse">
                <div class="col-sm-12 col-lg-7">
                  <h2>{{ Highlights[0].title }}</h2>
                  <p>
                    {{ Highlights[1].description }}
                  </p>
                </div>
                <div class="col-sm-12 col-lg-5 right-side">
                  <h2>Strong, Sustainable financial foundation</h2>

                  <BarChart :data-highlights="Highlights[1].highlights" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 first"></div>
        </div>
      </div>
    </div> -->

    <!-- Section Seven -->
    <div class="section_seven">
      <div class="container" v-if="Infrastructure && Infrastructure.length > 0">
        <h2>Our Infrastructures</h2>
        <div class="box">
          <div class="srv-box" v-for="(item, index) in Infrastructure" :key="index">
            <span>{{item.description}}</span>
            <p>{{item.title}}</p>
          </div>
        </div>
        <div class="boxtwo" v-if="Social">
          <div class="back">
            <div class="text-content">
              <h2>Social Responssibility</h2>
              <p>
                {{Social.description}}
              </p>
            </div>
            <div class="front"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Doughnut from "@/components/marketing/Doughnut.vue";
import newcards from "../components/marketing/newcard.vue";
import BarChart from "@/components/marketing/BarChart.vue";

export default {
  name: "marketingview",
  components: {
    newcards,
    Doughnut,
    BarChart,
  },
  data() {
    return {
      products: [
        {
          id: 1,
          text: "Governmental Hospitals, including the top 10 major hospitals in Yemen",
          src: require("../assets/images/frontcard.svg"),
        },
        {
          id: 2,
          text: "Governmental Hospitals, including the top 10 major hospitals in Yemen",
          src: require("../assets/images/frontcard.svg"),
        },
        {
          id: 3,
          text: "Governmental Hospitals, including the top 10 major hospitals in Yemen",
          src: require("../assets/images/frontcard.svg"),
        },
        {
          id: 4,
          text: "Governmental Hospitals, including the top 10 major hospitals in Yemen",
          src: require("../assets/images/frontcard.svg"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "MarketingPeople",
      "PeopleImages",
      "PharmaYemen",
      "Highlights",
      "Infrastructure",
      "Social",
    ]),
  },
  mounted() {
    this.getMarketing();
  },
  methods: {
    ...mapActions(["getMarketing"]),
    returnImage(value) {
      if (value == "quality assurance & regulatory affairs specialists") {
        return require("../assets/images/firstcardphoto_about.svg");
      } else if (value == "pharmacists") {
        return require("../assets/images/secondcardphoto_about.svg");
      } else if (value == "Sales Persons") {
        return require("../assets/images/thiredcardphoto_about.svg");
      } else if (value == "Logistics & Supply Chain Persons") {
        return require("../assets/images/fourthcardphoto_about.svg");
      } else if (value == "Financial , commercial & administrative person") {
        return require("../assets/images/firstcardphoto_about.svg");
      } else if (value == "Engineers") {
        return require("../assets/images/fifthcardphoto_about.svg");
      } else {
        return require("../assets/images/firstcardphoto_about.svg");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/_marketing.scss";
</style>
