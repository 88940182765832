<template>
  <div>
    <div class="news" v-if="News && News.length > 0">
      <div class="news-content">
        <div class="container">
          <div class="row">
            <div class="col-md-8 first_col">
              <div class="big-card card" style="width: 100%; height: 100%">
                <img
                  :src="'https://super.royalpharma.com//storage/contents/' + News[0].image"
                  class="img-fluid"
                  :alt="News[0].title"
                />
                <div class="card-body text-start">
                  <span class="badge bg-secondary">{{
                    getDate(News[0].created_at)
                  }}</span>
                  <h5 class="card-title">{{ News[0].title }}</h5>

                  <p
                    class="card-text"
                    v-if="!isMobile"
                    v-html="
                      News[0].description
                        ? News[0].description.slice(0, 200) + ' ...'
                        : ''
                    "
                  ></p>
                  <p
                    class="card-text"
                    v-else
                    v-html="
                      News[0].description
                        ? News[0].description.slice(0, 200) + ' ...'
                        : ''
                    "
                  ></p>
                  <span class="read_more">
                    <a
                      ><router-link :to="`/news/${News[0].id}`">Read More</router-link></a
                    >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.829"
                      height="11.947"
                      viewBox="0 0 16.829 11.947"
                    >
                      <g
                        id="Group_1438"
                        data-name="Group 1438"
                        transform="translate(1.151 0.848)"
                      >
                        <line
                          id="Line_3"
                          data-name="Line 3"
                          y1="0.372"
                          x2="15"
                          transform="translate(-0.383 5.122)"
                          fill="none"
                          stroke="#013299"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <line
                          id="Line_4"
                          data-name="Line 4"
                          y1="5"
                          x2="5"
                          transform="translate(9.617 5.039)"
                          fill="none"
                          stroke="#013299"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <line
                          id="Line_5"
                          data-name="Line 5"
                          x2="5"
                          y2="5"
                          transform="translate(9.617 0.212)"
                          fill="none"
                          stroke="#013299"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-4 second-col" v-if="News.length > 1">
              <div class="small-card card" style="width: 100%" v-if="News.length > 1">
                <img
                  class="img-fluid"
                  :src="'https://super.royalpharma.com//storage/contents/' + News[1].image"
                  :alt="News[1].title"
                />
                <div class="card-body text-start">
                  <span class="badge bg-secondary">{{
                    getDate(News[1].created_at)
                  }}</span>
                  <h5 class="card-title">{{ News[1].title }}</h5>
                  <p
                    class="card-text"
                    v-html="
                      News[1].description ? News[1].description.slice(0, 50) + ' ...' : ''
                    "
                  ></p>
                  <span class="read_more">
                    <a
                      ><router-link :to="`/news/${News[1].id}`">Read More</router-link></a
                    >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.829"
                      height="11.947"
                      viewBox="0 0 16.829 11.947"
                    >
                      <g
                        id="Group_1438"
                        data-name="Group 1438"
                        transform="translate(1.151 0.848)"
                      >
                        <line
                          id="Line_3"
                          data-name="Line 3"
                          y1="0.372"
                          x2="15"
                          transform="translate(-0.383 5.122)"
                          fill="none"
                          stroke="#013299"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <line
                          id="Line_4"
                          data-name="Line 4"
                          y1="5"
                          x2="5"
                          transform="translate(9.617 5.039)"
                          fill="none"
                          stroke="#013299"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <line
                          id="Line_5"
                          data-name="Line 5"
                          x2="5"
                          y2="5"
                          transform="translate(9.617 0.212)"
                          fill="none"
                          stroke="#013299"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div class="small-card card" style="width: 100%" v-if="News.length > 2">
                <img
                  class="card-img-top img-fluid"
                  :src="'https://super.royalpharma.com//storage/contents/' + News[2].image"
                  :alt="News[2].title"
                />
                <div class="card-body text-start">
                  <h3>
                    <span class="badge bg-secondary">
                      {{ getDate(News[2].created_at) }}</span
                    >
                  </h3>
                  <h5 class="card-title">{{ News[2].title }}</h5>
                  <p
                    class="card-text"
                    v-html="
                      News[2].description ? News[2].description.slice(0, 50) + ' ...' : ''
                    "
                  ></p>
                  <span class="read_more">
                    <a
                      ><router-link :to="`/news/${News[2].id}`">Read More</router-link></a
                    >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.829"
                      height="11.947"
                      viewBox="0 0 16.829 11.947"
                    >
                      <g
                        id="Group_1438"
                        data-name="Group 1438"
                        transform="translate(1.151 0.848)"
                      >
                        <line
                          id="Line_3"
                          data-name="Line 3"
                          y1="0.372"
                          x2="15"
                          transform="translate(-0.383 5.122)"
                          fill="none"
                          stroke="#013299"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <line
                          id="Line_4"
                          data-name="Line 4"
                          y1="5"
                          x2="5"
                          transform="translate(9.617 5.039)"
                          fill="none"
                          stroke="#013299"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <line
                          id="Line_5"
                          data-name="Line 5"
                          x2="5"
                          y2="5"
                          transform="translate(9.617 0.212)"
                          fill="none"
                          stroke="#013299"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row second-row" v-if="News.length > 3">
            <cards v-for="card in News.slice(3)" :key="card.id" :card="card" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no_data">
      <div class="innerdiv">
        <img src="../assets/images/nodata.svg" class="img-fluid" alt="nodata" />
        <h2><span>D</span>ata Not Found !</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import cards from "@/components/news/card.vue";
export default {
  components: { cards },
  name: "NewsView",
  data() {
    return {
      userId: this.$route.params.userId,
      isMobile: false,
    };
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  computed: {
    ...mapGetters(["News"]),
  },
  mounted() {
    this.getNews();
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    ...mapActions(["getNews"]),

    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    getDate(date) {
      if (date) {
        const today = new Date(date);
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = yyyy + "-" + dd + "-" + mm;

        return formattedToday;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/_news.scss";
</style>
