
export const MARKETINGPEOPLE = (state, marketing_people) => {

    state.marketing_people = marketing_people;
}
export const PEOPLEIMAGES = (state, people_images) => {

    state.people_images = people_images;
}
export const PHARMAYEMEN = (state, pharma_yemen) => {

    state.pharma_yemen = pharma_yemen;
}
export const HIGHLIGHTS = (state, highlights) => {

    state.highlights = highlights;
}
export const INFRASTRUCTURE = (state, infrastructure) => {

    state.infrastructure = infrastructure;
}
export const SOCIAL = (state, social) => {

    state.social = social;
}
export const MAKETINGSHOW = (state, marketing_show) => {

    state.marketing_show = marketing_show;
}
export const BUSINESSSHOW = (state, business_show) => {

    state.business_show = business_show;
}
