<template>
  <div class="big-box">
    <div class="srv-box" v-if="!isMobile">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="119.194"
        height="112.048"
        viewBox="0 0 119.194 112.048"
      >
        <g id="Group_1718" data-name="Group 1718" transform="translate(0 0)">
          <path
            id="Path_9284"
            data-name="Path 9284"
            d="M42.135.006c24.674,0,41.982,31.442,41.982,52.65,0,8.526-2.664,14.358-7.48,18.3C69.538,76.78,57.71,78.4,42.955,78.4,18.282,78.4,0,72.165,0,50.956,0,30.108,17.637.888,41.7.006,42.09-.008,41.715.006,42.135.006Z"
            transform="translate(77.136) rotate(60)"
            fill="#e2f3ff"
          />
          <g id="noun-medication-4091591" transform="translate(0 41.347)">
            <path
              id="Path_9285"
              data-name="Path 9285"
              d="M422.589,55.171a1.969,1.969,0,0,0,.453-1.247V46.788a1.99,1.99,0,0,0-1.986-1.986H402.045a1.99,1.99,0,0,0-1.986,1.986v7.129a1.966,1.966,0,0,0,.453,1.247h22.077Z"
              transform="translate(-364.617 -44.802)"
              fill="#013299"
            />
            <path
              id="Path_9286"
              data-name="Path 9286"
              d="M459.7,220.935a1.822,1.822,0,0,0-.195-.906,1.6,1.6,0,0,0-.537-.585,2.662,2.662,0,0,0-.913-.355,6.179,6.179,0,0,0-1.289-.125h-1.694a.294.294,0,0,0-.293.293v3.324a.294.294,0,0,0,.293.293h1.638a4.107,4.107,0,0,0,2.286-.523,1.557,1.557,0,0,0,.7-1.415Z"
              transform="translate(-412.525 -197.387)"
              fill="#013299"
            />
            <path
              id="Path_9287"
              data-name="Path 9287"
              d="M422.609,139.33H400.489a1.8,1.8,0,0,0-.432,1.157v32.6a1.625,1.625,0,0,0,1.624,1.624h19.743a1.625,1.625,0,0,0,1.624-1.624v-32.6a1.826,1.826,0,0,0-.439-1.157Zm-3.561,27.917h-1.986a.28.28,0,0,1-.237-.125l-2.077-2.913a.3.3,0,0,0-.481,0l-2.146,2.92a.293.293,0,0,1-.237.119H410.01a.3.3,0,0,1-.237-.474l3.317-4.355a.281.281,0,0,0,.007-.341l-3.2-4.753h.007l-1.345-2.007a.292.292,0,0,0-.244-.132H407.16a.294.294,0,0,0-.293.293v5.645a.294.294,0,0,1-.293.293h-1.617a.294.294,0,0,1-.293-.293v-13.6a.294.294,0,0,1,.293-.293h4.049a9.18,9.18,0,0,1,1.909.195,4.6,4.6,0,0,1,1.6.676,3.278,3.278,0,0,1,1.087,1.261,4.064,4.064,0,0,1,.383,1.847,3.8,3.8,0,0,1-.3,1.505,3.733,3.733,0,0,1-.808,1.178,3.9,3.9,0,0,1-1.205.794,4.463,4.463,0,0,1-.815.258l3.436,5.3a.3.3,0,0,0,.488.014l1.847-2.565a.3.3,0,0,1,.237-.125h1.874a.3.3,0,0,1,.237.474l-3.031,3.972a.283.283,0,0,0,0,.355l3.331,4.383a.3.3,0,0,1-.223.494Z"
              transform="translate(-364.616 -127.687)"
              fill="#013299"
            />
            <path
              id="Path_9288"
              data-name="Path 9288"
              d="M149,444.751a8.615,8.615,0,0,0-1.254-3.15,8.963,8.963,0,0,0-2.76-2.321c-.007-.007-.014-.014-.021-.014l-5.477,3.854a3.4,3.4,0,0,0-.822,4.732l.021.035a3.4,3.4,0,0,0,4.732.823l5.6-3.931c-.007-.014-.014-.021-.014-.028Z"
              transform="translate(-135.209 -390.431)"
              fill="#013299"
            />
            <path
              id="Path_9289"
              data-name="Path 9289"
              d="M208.583,402.83l-.021-.035a3.4,3.4,0,0,0-4.732-.822l-5.2,3.651A9.25,9.25,0,0,1,201.35,408a8.928,8.928,0,0,1,1.317,3.136l5.087-3.575a3.391,3.391,0,0,0,.829-4.732Z"
              transform="translate(-188.256 -357.235)"
              fill="#013299"
            />
            <path
              id="Path_9290"
              data-name="Path 9290"
              d="M297.182,488.425a7.714,7.714,0,0,1-5.471-3.408c-.021.069-.049.132-.063.195-.376,2.028,1.993,4.167,5.289,4.788s6.286-.53,6.662-2.557a1.97,1.97,0,0,0,.035-.349c-1.331,1.261-3.77,1.826-6.453,1.331Z"
              transform="translate(-269.663 -430.488)"
              fill="#013299"
            />
            <path
              id="Path_9291"
              data-name="Path 9291"
              d="M298.364,447.27c-1.944.286-3.4,1.254-3.658,2.634a2.907,2.907,0,0,0-.035.341v.16c0,.056.007.111.007.167s.021.146.035.216c.007.028.014.063.021.09.021.084.042.167.07.251.523,1.6,2.557,3.066,5.192,3.554a8.9,8.9,0,0,0,3.443-.014Z"
              transform="translate(-272.344 -397.438)"
              fill="#013299"
            />
            <path
              id="Path_9292"
              data-name="Path 9292"
              d="M332.885,446.736a9.629,9.629,0,0,0-2.209-.153l5,7.289a4.167,4.167,0,0,0,2.035-1.317,2.857,2.857,0,0,0,.265-.439.518.518,0,0,0,.049-.111,1.892,1.892,0,0,0,.153-.488c.384-2.021-1.986-4.167-5.289-4.78Z"
              transform="translate(-303.868 -396.827)"
              fill="#013299"
            />
            <path
              id="Path_9293"
              data-name="Path 9293"
              d="M128.265,342.163a3.973,3.973,0,0,1-1.394-.251l-9.463-3.554a3.918,3.918,0,0,1-1.826-1.4,3.237,3.237,0,0,0,2.07,2.613l9.463,3.554a3.275,3.275,0,0,0,4.216-1.916l.021-.063a3.582,3.582,0,0,0,.167-.7,3.979,3.979,0,0,1-3.254,1.721Z"
              transform="translate(-115.541 -300.772)"
              fill="#013299"
            />
            <path
              id="Path_9294"
              data-name="Path 9294"
              d="M121.6,299.494l2.592-6.077-4.516-1.694a3.174,3.174,0,0,0-.613-.16,1.871,1.871,0,0,0-.2-.021,2.8,2.8,0,0,0-.418-.021,1.4,1.4,0,0,0-.174.014,3.715,3.715,0,0,0-.4.056c-.063.014-.125.028-.181.042a2.857,2.857,0,0,0-.411.14c-.042.021-.084.028-.125.049a2.936,2.936,0,0,0-.495.286l-.1.084a3.318,3.318,0,0,0-.334.293c-.042.042-.084.09-.132.14-.09.1-.174.216-.258.328-.035.049-.07.1-.1.146a2.833,2.833,0,0,0-.265.543l-.021.063a3.352,3.352,0,0,0-.153.578c-.014.1-.021.188-.028.286s-.014.181-.014.265a3.626,3.626,0,0,0,.021.39.789.789,0,0,1,.014.125,3.2,3.2,0,0,0,.1.446,3.25,3.25,0,0,0,1.972,2.125Z"
              transform="translate(-115.25 -260.966)"
              fill="#013299"
            />
            <path
              id="Path_9295"
              data-name="Path 9295"
              d="M180.368,312.564a.026.026,0,0,0-.007-.014,3.872,3.872,0,0,0-.2-.537c-.014-.028-.035-.056-.049-.084a3.245,3.245,0,0,0-.258-.418c-.042-.056-.09-.1-.139-.16a3.387,3.387,0,0,0-.265-.293c-.084-.077-.181-.14-.272-.209a2.616,2.616,0,0,0-.223-.167,3.454,3.454,0,0,0-.578-.286l-4.3-1.617-2.6,6.091,4.579,1.714a3.313,3.313,0,0,0,1.15.209,3.275,3.275,0,0,0,3.066-2.125l.021-.063a2.109,2.109,0,0,0,.056-.223A3.177,3.177,0,0,0,180.368,312.564Z"
              transform="translate(-164.479 -276.089)"
              fill="#013299"
            />
            <path
              id="Path_9296"
              data-name="Path 9296"
              d="M306.335,351.079a3.885,3.885,0,0,0,3.533-2.125,2.464,2.464,0,0,0,.084-.328c0-.021.007-.042.007-.063a1.916,1.916,0,0,0,.014-.349c-.007-.09-.021-.181-.035-.265a1.565,1.565,0,0,0-.077-.258c-.432-1.171-2.021-1.979-3.861-1.861s-3.317,1.108-3.6,2.327a1.6,1.6,0,0,0-.042.265,1.9,1.9,0,0,0,.056.613.209.209,0,0,1,.014.063,2.415,2.415,0,0,0,.119.313,3.9,3.9,0,0,0,3.784,1.666Z"
              transform="translate(-279.074 -308.534)"
              fill="#013299"
            />
            <path
              id="Path_9297"
              data-name="Path 9297"
              d="M307.545,381.669a5.062,5.062,0,0,1-3.854-1.233c.355,1.247,1.993,2.112,3.9,1.993s3.422-1.185,3.616-2.467a5.032,5.032,0,0,1-3.658,1.707Z"
              transform="translate(-280.241 -338.437)"
              fill="#013299"
            />
            <path
              id="Path_9298"
              data-name="Path 9298"
              d="M257.644,266.77a11.593,11.593,0,0,0-3.652,1.749,3.345,3.345,0,0,0-.453.418,1.739,1.739,0,0,0-.286.4.634.634,0,0,0,.007.718.1.1,0,0,1,.021.028.639.639,0,0,0,.119.119c.007.007.014.007.021.014a1.11,1.11,0,0,0,.181.111h.007a2,2,0,0,0,.244.1,11.815,11.815,0,0,0,8.983-2.725c.056-.077.1-.146.146-.216v-.007c.035-.063.063-.132.09-.195,0-.007.007-.014.007-.021a.733.733,0,0,0,.035-.167c0-.014.007-.021.007-.035a.755.755,0,0,0-.021-.181c-.272-.892-2.718-.941-5.457-.111Z"
              transform="translate(-235.992 -238.756)"
              fill="#013299"
            />
            <path
              id="Path_9299"
              data-name="Path 9299"
              d="M261.74,283.987c-1.958.593-4.021.843-5.261.4a.753.753,0,0,0,.021.181c.272.892,2.711.941,5.45.111s4.738-2.23,4.467-3.122a.93.93,0,0,0-.084-.167c-.773,1.06-2.634,2-4.592,2.593Z"
              transform="translate(-238.904 -252.084)"
              fill="#013299"
            />
          </g>
        </g>
      </svg>

      <p v-if="title">{{ title }}</p>
      <div class="ouroffering">
        <div class="ouroffering-content" v-html="description ? description : ''">
          <!-- <li class="ouroffering-content" > -->
          <!-- <img src="../../assets/images/billshome.svg" alt="bills" />
            cradiovascular -->
          <!-- </li> -->
          <!-- <li>
            <img src="../../assets/images/billshome.svg" alt="bills" />
            Infectious Diseases
          </li>
          <li>
            <img src="../../assets/images/billshome.svg" alt="bills" />
            Women’s Health
          </li>
          <li>
            <img src="../../assets/images/billshome.svg" alt="bills" />
            Urology
          </li>
          <li>
            <img src="../../assets/images/billshome.svg" alt="bills" />
            Respiratory Diseases
          </li>
          <li>
            <img src="../../assets/images/billshome.svg" alt="bills" />
            Respiratory Diseases
          </li>
          <li>
            <img src="../../assets/images/billshome.svg" alt="bills" />
            Women’s Health
          </li>
          <li>
            <img src="../../assets/images/billshome.svg" alt="bills" />
            Infectious Diseases
          </li> -->
        </div>
      </div>
    </div>
    <div class="srv-box" v-else @click="isSelected = !isSelected">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="119.194"
        height="112.048"
        viewBox="0 0 119.194 112.048"
      >
        <g id="Group_1718" data-name="Group 1718" transform="translate(0 0)">
          <path
            id="Path_9284"
            data-name="Path 9284"
            d="M42.135.006c24.674,0,41.982,31.442,41.982,52.65,0,8.526-2.664,14.358-7.48,18.3C69.538,76.78,57.71,78.4,42.955,78.4,18.282,78.4,0,72.165,0,50.956,0,30.108,17.637.888,41.7.006,42.09-.008,41.715.006,42.135.006Z"
            transform="translate(77.136) rotate(60)"
            fill="#e2f3ff"
          />
          <g id="noun-medication-4091591" transform="translate(0 41.347)">
            <path
              id="Path_9285"
              data-name="Path 9285"
              d="M422.589,55.171a1.969,1.969,0,0,0,.453-1.247V46.788a1.99,1.99,0,0,0-1.986-1.986H402.045a1.99,1.99,0,0,0-1.986,1.986v7.129a1.966,1.966,0,0,0,.453,1.247h22.077Z"
              transform="translate(-364.617 -44.802)"
              fill="#013299"
            />
            <path
              id="Path_9286"
              data-name="Path 9286"
              d="M459.7,220.935a1.822,1.822,0,0,0-.195-.906,1.6,1.6,0,0,0-.537-.585,2.662,2.662,0,0,0-.913-.355,6.179,6.179,0,0,0-1.289-.125h-1.694a.294.294,0,0,0-.293.293v3.324a.294.294,0,0,0,.293.293h1.638a4.107,4.107,0,0,0,2.286-.523,1.557,1.557,0,0,0,.7-1.415Z"
              transform="translate(-412.525 -197.387)"
              fill="#013299"
            />
            <path
              id="Path_9287"
              data-name="Path 9287"
              d="M422.609,139.33H400.489a1.8,1.8,0,0,0-.432,1.157v32.6a1.625,1.625,0,0,0,1.624,1.624h19.743a1.625,1.625,0,0,0,1.624-1.624v-32.6a1.826,1.826,0,0,0-.439-1.157Zm-3.561,27.917h-1.986a.28.28,0,0,1-.237-.125l-2.077-2.913a.3.3,0,0,0-.481,0l-2.146,2.92a.293.293,0,0,1-.237.119H410.01a.3.3,0,0,1-.237-.474l3.317-4.355a.281.281,0,0,0,.007-.341l-3.2-4.753h.007l-1.345-2.007a.292.292,0,0,0-.244-.132H407.16a.294.294,0,0,0-.293.293v5.645a.294.294,0,0,1-.293.293h-1.617a.294.294,0,0,1-.293-.293v-13.6a.294.294,0,0,1,.293-.293h4.049a9.18,9.18,0,0,1,1.909.195,4.6,4.6,0,0,1,1.6.676,3.278,3.278,0,0,1,1.087,1.261,4.064,4.064,0,0,1,.383,1.847,3.8,3.8,0,0,1-.3,1.505,3.733,3.733,0,0,1-.808,1.178,3.9,3.9,0,0,1-1.205.794,4.463,4.463,0,0,1-.815.258l3.436,5.3a.3.3,0,0,0,.488.014l1.847-2.565a.3.3,0,0,1,.237-.125h1.874a.3.3,0,0,1,.237.474l-3.031,3.972a.283.283,0,0,0,0,.355l3.331,4.383a.3.3,0,0,1-.223.494Z"
              transform="translate(-364.616 -127.687)"
              fill="#013299"
            />
            <path
              id="Path_9288"
              data-name="Path 9288"
              d="M149,444.751a8.615,8.615,0,0,0-1.254-3.15,8.963,8.963,0,0,0-2.76-2.321c-.007-.007-.014-.014-.021-.014l-5.477,3.854a3.4,3.4,0,0,0-.822,4.732l.021.035a3.4,3.4,0,0,0,4.732.823l5.6-3.931c-.007-.014-.014-.021-.014-.028Z"
              transform="translate(-135.209 -390.431)"
              fill="#013299"
            />
            <path
              id="Path_9289"
              data-name="Path 9289"
              d="M208.583,402.83l-.021-.035a3.4,3.4,0,0,0-4.732-.822l-5.2,3.651A9.25,9.25,0,0,1,201.35,408a8.928,8.928,0,0,1,1.317,3.136l5.087-3.575a3.391,3.391,0,0,0,.829-4.732Z"
              transform="translate(-188.256 -357.235)"
              fill="#013299"
            />
            <path
              id="Path_9290"
              data-name="Path 9290"
              d="M297.182,488.425a7.714,7.714,0,0,1-5.471-3.408c-.021.069-.049.132-.063.195-.376,2.028,1.993,4.167,5.289,4.788s6.286-.53,6.662-2.557a1.97,1.97,0,0,0,.035-.349c-1.331,1.261-3.77,1.826-6.453,1.331Z"
              transform="translate(-269.663 -430.488)"
              fill="#013299"
            />
            <path
              id="Path_9291"
              data-name="Path 9291"
              d="M298.364,447.27c-1.944.286-3.4,1.254-3.658,2.634a2.907,2.907,0,0,0-.035.341v.16c0,.056.007.111.007.167s.021.146.035.216c.007.028.014.063.021.09.021.084.042.167.07.251.523,1.6,2.557,3.066,5.192,3.554a8.9,8.9,0,0,0,3.443-.014Z"
              transform="translate(-272.344 -397.438)"
              fill="#013299"
            />
            <path
              id="Path_9292"
              data-name="Path 9292"
              d="M332.885,446.736a9.629,9.629,0,0,0-2.209-.153l5,7.289a4.167,4.167,0,0,0,2.035-1.317,2.857,2.857,0,0,0,.265-.439.518.518,0,0,0,.049-.111,1.892,1.892,0,0,0,.153-.488c.384-2.021-1.986-4.167-5.289-4.78Z"
              transform="translate(-303.868 -396.827)"
              fill="#013299"
            />
            <path
              id="Path_9293"
              data-name="Path 9293"
              d="M128.265,342.163a3.973,3.973,0,0,1-1.394-.251l-9.463-3.554a3.918,3.918,0,0,1-1.826-1.4,3.237,3.237,0,0,0,2.07,2.613l9.463,3.554a3.275,3.275,0,0,0,4.216-1.916l.021-.063a3.582,3.582,0,0,0,.167-.7,3.979,3.979,0,0,1-3.254,1.721Z"
              transform="translate(-115.541 -300.772)"
              fill="#013299"
            />
            <path
              id="Path_9294"
              data-name="Path 9294"
              d="M121.6,299.494l2.592-6.077-4.516-1.694a3.174,3.174,0,0,0-.613-.16,1.871,1.871,0,0,0-.2-.021,2.8,2.8,0,0,0-.418-.021,1.4,1.4,0,0,0-.174.014,3.715,3.715,0,0,0-.4.056c-.063.014-.125.028-.181.042a2.857,2.857,0,0,0-.411.14c-.042.021-.084.028-.125.049a2.936,2.936,0,0,0-.495.286l-.1.084a3.318,3.318,0,0,0-.334.293c-.042.042-.084.09-.132.14-.09.1-.174.216-.258.328-.035.049-.07.1-.1.146a2.833,2.833,0,0,0-.265.543l-.021.063a3.352,3.352,0,0,0-.153.578c-.014.1-.021.188-.028.286s-.014.181-.014.265a3.626,3.626,0,0,0,.021.39.789.789,0,0,1,.014.125,3.2,3.2,0,0,0,.1.446,3.25,3.25,0,0,0,1.972,2.125Z"
              transform="translate(-115.25 -260.966)"
              fill="#013299"
            />
            <path
              id="Path_9295"
              data-name="Path 9295"
              d="M180.368,312.564a.026.026,0,0,0-.007-.014,3.872,3.872,0,0,0-.2-.537c-.014-.028-.035-.056-.049-.084a3.245,3.245,0,0,0-.258-.418c-.042-.056-.09-.1-.139-.16a3.387,3.387,0,0,0-.265-.293c-.084-.077-.181-.14-.272-.209a2.616,2.616,0,0,0-.223-.167,3.454,3.454,0,0,0-.578-.286l-4.3-1.617-2.6,6.091,4.579,1.714a3.313,3.313,0,0,0,1.15.209,3.275,3.275,0,0,0,3.066-2.125l.021-.063a2.109,2.109,0,0,0,.056-.223A3.177,3.177,0,0,0,180.368,312.564Z"
              transform="translate(-164.479 -276.089)"
              fill="#013299"
            />
            <path
              id="Path_9296"
              data-name="Path 9296"
              d="M306.335,351.079a3.885,3.885,0,0,0,3.533-2.125,2.464,2.464,0,0,0,.084-.328c0-.021.007-.042.007-.063a1.916,1.916,0,0,0,.014-.349c-.007-.09-.021-.181-.035-.265a1.565,1.565,0,0,0-.077-.258c-.432-1.171-2.021-1.979-3.861-1.861s-3.317,1.108-3.6,2.327a1.6,1.6,0,0,0-.042.265,1.9,1.9,0,0,0,.056.613.209.209,0,0,1,.014.063,2.415,2.415,0,0,0,.119.313,3.9,3.9,0,0,0,3.784,1.666Z"
              transform="translate(-279.074 -308.534)"
              fill="#013299"
            />
            <path
              id="Path_9297"
              data-name="Path 9297"
              d="M307.545,381.669a5.062,5.062,0,0,1-3.854-1.233c.355,1.247,1.993,2.112,3.9,1.993s3.422-1.185,3.616-2.467a5.032,5.032,0,0,1-3.658,1.707Z"
              transform="translate(-280.241 -338.437)"
              fill="#013299"
            />
            <path
              id="Path_9298"
              data-name="Path 9298"
              d="M257.644,266.77a11.593,11.593,0,0,0-3.652,1.749,3.345,3.345,0,0,0-.453.418,1.739,1.739,0,0,0-.286.4.634.634,0,0,0,.007.718.1.1,0,0,1,.021.028.639.639,0,0,0,.119.119c.007.007.014.007.021.014a1.11,1.11,0,0,0,.181.111h.007a2,2,0,0,0,.244.1,11.815,11.815,0,0,0,8.983-2.725c.056-.077.1-.146.146-.216v-.007c.035-.063.063-.132.09-.195,0-.007.007-.014.007-.021a.733.733,0,0,0,.035-.167c0-.014.007-.021.007-.035a.755.755,0,0,0-.021-.181c-.272-.892-2.718-.941-5.457-.111Z"
              transform="translate(-235.992 -238.756)"
              fill="#013299"
            />
            <path
              id="Path_9299"
              data-name="Path 9299"
              d="M261.74,283.987c-1.958.593-4.021.843-5.261.4a.753.753,0,0,0,.021.181c.272.892,2.711.941,5.45.111s4.738-2.23,4.467-3.122a.93.93,0,0,0-.084-.167c-.773,1.06-2.634,2-4.592,2.593Z"
              transform="translate(-238.904 -252.084)"
              fill="#013299"
            />
          </g>
        </g>
      </svg>

      <p v-if="title">{{ title }}</p>
      <div class="ouroffering">
        <div
          :class="{ add: isSelected }"
          v-show="isSelected"
          class="ouroffering-content"
          v-html="description ? description : ''"
        >
          <!-- <li></li> -->
          <!-- <li>
            <img src="../../assets/images/billshome.svg" alt="bills" />
            Infectious Diseases
          </li>
          <li>
            <img src="../../assets/images/billshome.svg" alt="bills" />
            Women’s Health
          </li>
          <li>
            <img src="../../assets/images/billshome.svg" alt="bills" />
            Urology
          </li>
          <li>
            <img src="../../assets/images/billshome.svg" alt="bills" />
            Respiratory Diseases
          </li>
          <li>
            <img src="../../assets/images/billshome.svg" alt="bills" />
            Respiratory Diseases
          </li>
          <li>
            <img src="../../assets/images/billshome.svg" alt="bills" />
            Women’s Health
          </li>
          <li>
            <img src="../../assets/images/billshome.svg" alt="bills" />
            Infectious Diseases
          </li> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ouroffering",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isSelected: false,
    };
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    togclass() {},
  },
};
</script>

<style lang="scss">
.big-box {
  position: relative;
  transition: 0.5s ease-in-out;
  z-index: 2;
  .srv-box {
    height: 259px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgb(0, 0, 0, 15%);
    display: grid;
    place-content: center;
    cursor: pointer;
    svg {
      margin: auto;
    }
    p {
      font-size: 24px;
      line-height: 35px;
      font-family: Ubuntu-B;
      width: 240px;
      height: 62px;
      margin-top: 17.5px;
      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
    .ouroffering {
      & ul {
        padding: 0;
      }
      & .ouroffering-content {
        width: 413px;
        min-height: 250px;
        background-color: #e2f3ff;
        border-radius: 0 0 20px 20px;
        position: absolute;
        top: 93%;
        left: 50%;
        transform: translate(-50%, -95%);
        opacity: 0;
        z-index: -1;
        transition: all 0.2s ease-in-out;

        @media (max-width: 768px) {
          width: 100%;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          width: 100%;
        }
        @media (min-width: 991px) and (max-width: 1366px) {
          width: 353px;
        }
        & p {
          text-align: left;
          font-size: 20px;
          margin: 0;
          padding: 0;
          color: var(--color_text_buttontwo);
          opacity: 1;
          transition: all 0.2s ease-in-out;
          width: inherit;
          height: inherit;
          @media screen and (max-width: 768px) {
            font-size: 17px;
          }
        }
        & li {
          text-align: left;
          font-size: 20px;
          margin: 0;
          padding: 0;
          color: var(--color_text_buttontwo);
          opacity: 1;
          transition: all 0.2s ease-in-out;
          @media screen and (max-width: 768px) {
            font-size: 17px;
          }

          padding-left: 30px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 8px;
            width: 20px;
            height: 20px;
            background-image: url("../../assets/images/billshome.svg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }

          // img {
          // @media screen and (max-width: 768px) {
          //   width: 6%;
          // }
          // @media (min-width: 768px) and (max-width: 991px) {
          //   width: 3%;
          // }
          // }
        }
      }
    }
  }
}
.big-box .srv-box:hover .ouroffering-content {
  min-height: 310px;
  padding: 38.9px 0px 24px 32px;
  transform: translate(-50%, 0);
  transition: all 0.2s ease-in-out;
  opacity: 1;
  @media screen and (max-width: 768px) {
    min-height: 270px;
  }
  & li {
    // opacity: 1;
    transition-delay: 0.1s;
  }
}
.big-box .srv-box:hover .ouroffering-content {
  min-height: 310px;
  padding: 38.9px 0px 24px 32px;
  transform: translate(-50%, 0);
  transition: all 0.2s ease-in-out;
  opacity: 1;
  @media screen and (max-width: 768px) {
    min-height: 270px;
  }
  & li {
    // opacity: 1;
    transition-delay: 0.1s;
  }
}
</style>
