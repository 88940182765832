import axios from "../../libs/axios";

export const getContact = ({ commit }) => {
  axios
    .get("contents/contact_us")
    .then((response) => {
      commit("CONTACT", response.data.data);
    })
    .catch((errors) => {});
};

export const setContactMessage = ({ commit }, data) => {
  axios
    .post("subscribe", data)
    .then((response) => {})
    .catch((errors) => {});
};
