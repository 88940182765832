
export const ABOUTHERO = (state, hero) => {

    state.about_hero = hero;
}
export const ABOUTVISION = (state, vision) => {

    state.about_vision = vision;
}
export const ABOUTMANAGE = (state, manage) => {

    state.about_manage = manage;
}
export const ABOUTPRINCIPLES = (state, principles) => {

    state.about_principles = principles;
}
export const ABOUTSHOW = (state, show) => {

    state.about_show = show;
}
