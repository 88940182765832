export default {
    marketing_people: null,
    people_images: null,
    pharma_yemen: null,
    highlights: null,
    infrastructure: null,
    social: null,
    marketing_show: null,
    business_show: null,
}
