<template>
  <div class="contactus">
    <div class="container">
      <div class="content-news">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="first-content text-md-start">
              <h2>Get in Touch with Us</h2>
              <p>
                you can contact us throw submitting the info that you want to ask us in
                the form
              </p>
              <form>
                <label for="">Your name</label>
                <input type="text" placeholder="Your name" v-model="formData.name" />
                <label for="">Your email</label>
                <input type="text" placeholder="Your email" v-model="formData.email" />
                <label for="">Your message</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Your message"
                  v-model="formData.message"
                ></textarea>
                <br />
                <button
                  class="submit-form"
                  :disabled="disabled"
                  type="button"
                  @click="onSubmit"
                >
                  <div
                    v-if="spinning"
                    class="spinner-border text-light"
                    role="status"
                  ></div>
                  <template v-else> Send </template>
                </button>
              </form>
            </div>
          </div>
          <div class="col-sm-12 col-md-6" v-if="Contact">
            <div class="second-content">
              <googlemapping
                class="googlemap"
                :lat="Contact.lat ? parseFloat(Contact.lat) : 0"
                :lng="Contact.lng ? parseFloat(Contact.lng) : 0"
              />
              <div class="list_links">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.975"
                    height="24"
                    viewBox="0 0 16.975 24"
                  >
                    <g id="noun-location-1755663" transform="translate(-0.001)">
                      <path
                        id="Path_4344"
                        data-name="Path 4344"
                        d="M196.088,50.395a8.65,8.65,0,0,0-8.488,8.78,8.112,8.112,0,0,0,1.335,4.436l6.4,10.362h0a.878.878,0,0,0,1.5,0l6.4-10.362a8.115,8.115,0,0,0,1.335-4.436,8.65,8.65,0,0,0-8.488-8.78Zm0,1.756a6.87,6.87,0,0,1,6.732,7.024,6.1,6.1,0,0,1-1.079,3.512l-5.652,9.146-5.652-9.146a6.1,6.1,0,0,1-1.079-3.512A6.87,6.87,0,0,1,196.088,52.151Zm0,2.341a4.39,4.39,0,1,0,4.39,4.39A4.4,4.4,0,0,0,196.088,54.493Zm0,1.756a2.634,2.634,0,1,1-2.634,2.634A2.621,2.621,0,0,1,196.088,56.249Z"
                        transform="translate(-187.599 -50.395)"
                        fill="#71b7bf"
                      />
                    </g>
                  </svg>

                  <a href="#">{{ Contact.location }} - {{ Contact.branch_name }}</a>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="19.33"
                    viewBox="0 0 24 19.33"
                  >
                    <g id="noun-email-2354715" transform="translate(0)">
                      <path
                        id="Path_4306"
                        data-name="Path 4306"
                        d="M100.071,112A2.335,2.335,0,0,0,98,114.507v14.306a2.345,2.345,0,0,0,2.075,2.517h19.85A2.345,2.345,0,0,0,122,128.813V114.507A2.335,2.335,0,0,0,119.921,112Zm0,1.933h19.85a.51.51,0,0,1,.475.574v.181L110,122.772l-10.4-8.084v-.181a.51.51,0,0,1,.475-.574Zm-.475,3.05,9.967,7.742a.686.686,0,0,0,.867,0l9.967-7.742v11.829a.519.519,0,0,1-.475.584h-19.85a.519.519,0,0,1-.475-.584Z"
                        transform="translate(-97.996 -112)"
                        fill="#71b7bf"
                      />
                    </g>
                  </svg>
                  <a :href="'mailto:' + Contact.email">{{ Contact.email }}</a>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="26.074"
                    viewBox="0 0 24 26.074"
                  >
                    <g id="noun-call-2442970" transform="translate(0.001)">
                      <path
                        id="Path_4411"
                        data-name="Path 4411"
                        d="M89.64,25.925l-1.058-.016-.3-.063c-.24-.049-.494-.1-.737-.164a14.053,14.053,0,0,1-4.368-2.177,36.85,36.85,0,0,1-6.051-5.474h0a39.207,39.207,0,0,1-4.61-5.943,17.162,17.162,0,0,1-2.237-4.995c-.082-.323-.137-.644-.19-.954-.024-.133-.046-.261-.07-.4L70,5.58V4.652l.058-.151A7.377,7.377,0,0,1,71.9,1.9c.514-.521,1.056-1,1.565-1.436a2.337,2.337,0,0,1,2.213-.521,1.976,1.976,0,0,1,1.2.925l.658,1.077c.686,1.136,1.394,2.31,2.114,3.444a2.9,2.9,0,0,1,.474,1.4,2.954,2.954,0,0,1-.27,1.465c-.127.3-.274.587-.415.86l-.18.352c-.134.261-.266.521-.406.782l.451.587c.406.521.828,1.082,1.26,1.588h0a33.608,33.608,0,0,0,3.857,3.91c.509-.321,1.042-.644,1.625-.988h0A2.525,2.525,0,0,1,88,14.977a1.98,1.98,0,0,1,.667.307q2.23,1.593,4.447,3.2a2.537,2.537,0,0,1,.758,2.781,2.648,2.648,0,0,1-.326.722,15.145,15.145,0,0,1-2.729,3.157,4.956,4.956,0,0,1-.72.49c-.091.055-.18.107-.269.162ZM88.8,24.254h.427l.13-.076a3.687,3.687,0,0,0,.509-.339A13.627,13.627,0,0,0,92.3,21a1.023,1.023,0,0,0,.106-.261.859.859,0,0,0,.047-.458.821.821,0,0,0-.2-.41q-2.215-1.6-4.437-3.191a.517.517,0,0,0-.166-.076,1.144,1.144,0,0,0-.876.2c-.646.378-1.224.732-1.769,1.082a1.055,1.055,0,0,1-.677.2,1.075,1.075,0,0,1-.652-.279,34.555,34.555,0,0,1-4.234-4.249c-.463-.54-.9-1.108-1.318-1.658-.223-.284-.449-.566-.66-.847a1.168,1.168,0,0,1-.265-.669,1.191,1.191,0,0,1,.171-.7c.19-.339.365-.683.54-1.024l.187-.362c.132-.261.254-.49.353-.727h0a1.174,1.174,0,0,0,.137-.623,1.155,1.155,0,0,0-.206-.6c-.72-1.15-1.44-2.333-2.134-3.475L75.61,1.792a.439.439,0,0,0-.29-.219.9.9,0,0,0-.888.193c-.48.425-1.006.881-1.483,1.361a6.143,6.143,0,0,0-1.411,1.861v.506c.022.112.041.224.06.336.05.292.1.566.163.826a15.591,15.591,0,0,0,2.028,4.5h0a37.606,37.606,0,0,0,4.423,5.7A35.433,35.433,0,0,0,84,22.1a12.587,12.587,0,0,0,3.907,1.963c.211.057.427.1.643.141Z"
                        transform="translate(-69.999 0.149)"
                        fill="#71b7bf"
                      />
                    </g>
                  </svg>

                  <a :href="'tel:' + Contact.phone_number">{{ Contact.phone_number }}</a>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.414"
                    height="24"
                    viewBox="0 0 19.414 24"
                  >
                    <g id="noun-fax-1254546" transform="translate(0)">
                      <path
                        id="Path_4383"
                        data-name="Path 4383"
                        d="M181.4,298.7h11.226a.432.432,0,1,0,0-.86H181.4A.432.432,0,1,0,181.4,298.7Z"
                        transform="translate(-177.474 -284.999)"
                        fill="#71b7bf"
                      />
                      <path
                        id="Path_4384"
                        data-name="Path 4384"
                        d="M211.493,309.694q.007,2.439.015,4.878c0,.732.048,1.486.007,2.217a2.536,2.536,0,0,1-.148,1.408q-.064.955-.861.521a13.539,13.539,0,0,1-3.552,0l-4.262,0,.515.674a57.642,57.642,0,0,1,.227-9.387c.13-.847-.863-1.209-.993-.358a59.492,59.492,0,0,0-.263,9.745c0,.364.232.674.515.674l5.683,0c.994,0,2.718.462,3.6-.215.832-.635.569-1.985.566-3.062q-.011-3.547-.022-7.095c0-.867-1.033-.868-1.03,0Z"
                        transform="translate(-198.016 -296.22)"
                        fill="#71b7bf"
                      />
                      <path
                        id="Path_4385"
                        data-name="Path 4385"
                        d="M271.307,349.394h5.529a.653.653,0,0,0,0-1.306h-5.529A.653.653,0,0,0,271.307,349.394Z"
                        transform="translate(-264.666 -332.868)"
                        fill="#71b7bf"
                      />
                      <path
                        id="Path_4386"
                        data-name="Path 4386"
                        d="M271.307,404.144h5.834a.653.653,0,0,0,0-1.306h-5.834A.653.653,0,0,0,271.307,404.144Z"
                        transform="translate(-264.715 -385.018)"
                        fill="#71b7bf"
                      />
                      <path
                        id="Path_4387"
                        data-name="Path 4387"
                        d="M276.687,456.194H282a.653.653,0,0,0,0-1.306h-5.311A.653.653,0,0,0,276.687,456.194Z"
                        transform="translate(-269.837 -434.648)"
                        fill="#71b7bf"
                      />
                      <path
                        id="Path_4388"
                        data-name="Path 4388"
                        d="M89,199.882c-1.269.01-3.268.635-3.681-1.386a17.73,17.73,0,0,1-.024-4.894c.754-2.188,2.974-1.6,4.426-1.607l4.317-.016,3.924-.014c1.534-.006,3.945-.809,4.66,1.628a15.609,15.609,0,0,1,.04,4.869c-.624,1.968-3.072,1.277-4.35,1.24-.633-.018-.633,1.288,0,1.306,1.17.034,4.084.762,5-.456a4.036,4.036,0,0,0,.331-2.09,47.413,47.413,0,0,0-.04-4.869c-.106-1.415-.38-2.571-1.526-2.9a12.523,12.523,0,0,0-3.332-.034l-4.317.016c-2.616.009-5.232,0-7.848.029-1.479.017-2.119.388-2.259,2.476-.107,1.594-.008,3.275.011,4.875a5.425,5.425,0,0,0,.241,2.3c.823,1.572,3.284.848,4.426.84.633,0,.634-1.311,0-1.306Z"
                        transform="translate(-84.269 -182.926)"
                        fill="#71b7bf"
                      />
                      <path
                        id="Path_4389"
                        data-name="Path 4389"
                        d="M502.846,239.914h1.045a.653.653,0,0,0,0-1.306h-1.045A.653.653,0,0,0,502.846,239.914Z"
                        transform="translate(-488.04 -228.587)"
                        fill="#71b7bf"
                      />
                      <path
                        id="Path_4390"
                        data-name="Path 4390"
                        d="M194.127,240.8a.653.653,0,0,0,0-1.306A.653.653,0,0,0,194.127,240.8Z"
                        transform="translate(-189.453 -229.605)"
                        fill="#71b7bf"
                      />
                      <path
                        id="Path_4391"
                        data-name="Path 4391"
                        d="M146.559,237.214a.653.653,0,0,0,0-1.306A.653.653,0,0,0,146.559,237.214Z"
                        transform="translate(-143.001 -226.015)"
                        fill="#71b7bf"
                      />
                      <path
                        id="Path_4392"
                        data-name="Path 4392"
                        d="M210.424,35.608q.027-3.659.054-7.319l-.623.653q5.5-.069,10.991-.028l-.623-.653q-.022,3.674-.044,7.347a.623.623,0,1,0,1.246,0q.022-3.674.044-7.347a.648.648,0,0,0-.623-.653q-5.5-.041-10.991.028a.649.649,0,0,0-.623.653q-.027,3.659-.054,7.319A.623.623,0,1,0,210.424,35.608Z"
                        transform="translate(-205.833 -27.593)"
                        fill="#71b7bf"
                      />
                    </g>
                  </svg>
                  <a :href="'fax:' + Contact.fax">{{ Contact.fax }}</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import googlemapping from "@/components/global/map.vue";
export default {
  name: "ContactusView",
  components: {
    googlemapping,
  },
  data() {
    return {
      spinning: false,
      disabled: false,
      formData: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  computed: {
    ...mapGetters(["Contact"]),
  },
  mounted() {
    this.getContact();
  },
  methods: {
    ...mapActions(["getContact", "setContactMessage"]),
    onSubmit() {
      this.spinning = true;
      this.disabled = true;
      const data = {
        name: this.formData.name,
        email: this.formData.email,
        message: this.formData.message,
      };
      this.setContactMessage(data).then(() => {
        this.spinning = false;
        this.disabled = false;
        this.formData = {
          name: "",
          email: "",
          message: "",
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/assets/scss/pages/_contact.scss";
</style>
