import Vue from "vue";
import Vuex from "vuex";
import { home } from "./home";
import { about } from "./about";
import { marketing } from "./market";
import { news } from "./news";
import { contact } from "./contact";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    home,
    about,
    marketing,
    news,
    contact
  },
  strict: process.env.DEV,
});
