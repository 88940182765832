<template>
  <div class="subnews" v-if="News_Show">
    <div class="first-section">
      <div class="container">
        <div class="first-row">
          <div class="row">
            <div class="col second-col">
              <div class="small-card card" style="width: 100%; height: 100%">
                <img
                  :src="'https://super.royalpharma.com//storage/contents/' + News_Show.image"
                  :alt="News_Show.title"
                />
                <div class="card-body text-md-start">
                  <span class="badge bg-secondary">{{
                    getDate(News_Show.created_at)
                  }}</span>
                  <h5 class="card-title">{{ News_Show.title }}</h5>
                  <p class="card-text" v-html="News_Show.description"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="second-row" v-if="News && News.length > 1">
          <div class="row gy-3">
            <h2>similar articles</h2>
            <card
              v-for="(card, index) in News"
              :key="index"
              :card="card"
              v-if="index < 4 && card.id != $route.params.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import card from "@/components/news/card.vue";
export default {
  components: { card },
  name: "Subnewsviews",
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["News_Show", "News"]),
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.NewsShow(id);
    }
    this.getNews();
  },
  methods: {
    ...mapActions(["NewsShow", "getNews"]),
    getDate(date) {
      if (date) {
        const today = new Date(date);
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = yyyy + "-" + dd + "-" + mm;

        return formattedToday;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.first-row {
  font-family: Ubuntu-R;
  padding-top: 144px;
  padding-bottom: 96px;
  @media (max-width: 991px) {
    padding-top: 100px;
  }
  .second-col {
    .small-card {
      border: none;
      box-shadow: 0 3px 6px rgb(0, 0, 0, 16%);
      padding: 24px;
      border-radius: 20px;
      img {
        height: 500px;
        object-fit: cover;
        @media (max-width: 768px) {
          height: 200px;
        }
      }
      .card-body {
        padding: 0;
        padding-top: 39px;
        span {
          font-size: 18px;
          font-weight: normal;
          background-color: #e2f3ff !important;
          color: #1d8d99;
          border-radius: 10px !important;
          padding: 12px;
          width: fit-content;
          @media (max-width: 768px) {
            font-size: 14px;
            display: flex;
          }
        }

        h5 {
          font-size: 3rem;
          line-height: 55px;
          color: var(--color_text_buttontwo);
          margin-top: 24px;
          margin-bottom: 24px;
          font-family: Ubuntu-M;
          @media (max-width: 768px) {
            font-size: 20px;
            text-align: left;
            margin-bottom: 16px;
            margin-top: 8px;
          }
        }
        p {
          line-height: 38.4px;
          color: rgb(112, 112, 112, 100%);
          font-size: 1.5rem;
          position: relative;
          @media (max-width: 768px) {
            font-size: 14px;
            text-align: left;
          }
        }
        .card-text.speacial {
          padding-left: 24px;
        }
        .card-text.speacial::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          height: 85%;
          width: 10px;
          background-color: #1d8d99;
          transform: translateY(-50%);
        }
        .read_more {
          display: flex;
          align-items: center;
          column-gap: 10px;
          justify-content: flex-end;
          a {
            text-decoration: none;
            color: var(--color_text_buttontwo);
            font-weight: 600;
          }
          svg {
            font-weight: 600;
          }
        }
      }
    }
  }
}
.second-row {
  padding-bottom: 40px;
  h2 {
    font-size: 3rem;
    color: var(--color_text_buttontwo);
    text-align: left;
    margin-bottom: 24px;
    @media (max-width: 768px) {
      font-size: 40px;
      text-align: center;
    }
  }
}
</style>
