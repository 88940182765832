import axios from "../../libs/axios";

export const getMarketing = ({ commit }) => {
  axios
    .get("contents/marketing/our_people_section")
    .then((response) => {
      commit("MARKETINGPEOPLE", response.data.data);
    })
    .catch((errors) => {});
  axios
    .get("contents/marketing/our_people_cards")
    .then((response) => {
      commit("PEOPLEIMAGES", response.data.data);
    })
    .catch((errors) => {});
  axios
    .get("contents/marketing/royal_pharma_yemen")
    .then((response) => {
      commit("PHARMAYEMEN", response.data.data);
    })
    .catch((errors) => {});
  axios
    .get("contents/marketing/business_highlights")
    .then((response) => {
      commit("HIGHLIGHTS", response.data.data);
    })
    .catch((errors) => {});
  axios
    .get("contents/marketing/infrastructure")
    .then((response) => {
      commit("INFRASTRUCTURE", response.data.data);
    })
    .catch((errors) => {});
  axios
    .get("contents/marketing/social")
    .then((response) => {
      commit("SOCIAL", response.data.data);
    })
    .catch((errors) => {});
};
