<template>
  <div style="width: 300px !important; margin: auto">
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { read } from "@popperjs/core";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    dataHighlights: {
      type: Array,
      default: [],
    },
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 311,
    },
    height: {
      type: Number,
      default: 246,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Seles in Million USF",
            color: "#FFF",
            backgroundColor: "#EB0E0E",
            data: [],
          },
        ],
      },

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    if (this.dataHighlights && this.dataHighlights.length > 0) {
      for (let index = 0; index < this.dataHighlights.length; index++) {
        const element = this.dataHighlights[index];
        this.chartData.labels.push(element.year);
        this.chartData.datasets[0].data.push(element.sales);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.Barchartone {
  width: 200px;
}
</style>
