import axios from "../../libs/axios";

export const getNews = ({ commit }) => {
  axios
    .get("contents/news")
    .then((response) => {
      commit("NEWS", response.data.data);
    })
    .catch((errors) => {});
};

export const NewsShow = ({ commit }, id) => {
  axios
    .get("contents/news/show/" + id)
    .then((response) => {
      commit("NEWSSHOW", response.data.data);
    })
    .catch((errors) => {});
};
